import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Head from '../components/head'

const AboutPage = () => {
    return (
        <Layout>
            <Head title="About"/>
            <h1>About Us</h1>
            <p>If this were anything real, here's where I'd discuss that hypothetical realness.</p>
            <p><Link to="/contact">Please reach out here.</Link></p>
        </Layout>
    )
}

export default AboutPage